<template>
  <div id="account">
    <cent-title title="内部账号管理"></cent-title>
    <div v-if="!isLimints">
      <div class="user">
        <div>姓名：</div>
        <el-input
          v-model="userName"
          placeholder="请输入姓名"
          clearable
          @clear="clearInfo"
        ></el-input>
        <el-button @click="queryName">查询</el-button>
        <div>部门名称：</div>
        <el-select
          v-model="department"
          placeholder="请选择部门名称"
          clearable
          @clear="clearInfo"
        >
          <el-option
            v-for="item in departmentList"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <el-button @click="querySection">查询</el-button>
      </div>
      <el-button size="mini" icon="el-icon-plus" class="add" @click="addList"
        >新增</el-button
      >
      <el-button
        size="mini"
        icon="el-icon-delete"
        class="delete"
        @click="getAccountDel"
        >删除</el-button
      >
      <el-table
        :data="accountList"
        @select="selectAll"
        @select-all="selectAll"
        border
        style="width: 100%"
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          width="80"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="username"
          label="姓名"
          width="200"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="account"
          label="账号"
          width="200"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="password"
          label="密码"
          width="200"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="电话号码"
          width="200"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="department"
          label="所在部门"
          width="200"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="role"
          label="账号属性"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.role === 1">组员</div>
            <div v-else-if="scope.row.role === 2">管理员</div>
            <div v-else-if="scope.row.role === 3">超级管理员</div>
            <div v-else-if="scope.row.role === 0">游客</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              class="update"
              icon="el-icon-edit"
              @click="extensionEdit(scope.row)"
              >修改</el-button
            >
            <el-button
              class="delete"
              icon="el-icon-delete"
              @click="extensionDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 修改弹框 -->
      <my-amend v-show="isAmend" @btnClose="isAmend = false">
        <el-form
          :model="accountForm"
          :rules="accountules"
          ref="accountForm"
          label-width="100px"
          class="accountForm"
        >
          <div class="isTitle">个人信息</div>
          <el-form-item label="姓名：" prop="username">
            <el-input
              v-model.trim="accountForm.username"
              placeholder="请填写姓名"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="账号：" prop="account">
            <el-input
              v-model.trim="accountForm.account"
              placeholder="请填写账号"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码：" prop="password">
            <el-input
              type="password"
              v-model.trim="accountForm.password"
              placeholder="请填写密码"
              maxlength="18"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码：" prop="phone">
            <el-input
              v-model.number="accountForm.phone"
              placeholder="请填写手机号码"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属部门：" prop="department">
            <el-select
              v-model="accountForm.department"
              placeholder="请选择部门名称"
            >
              <el-option
                v-for="item in departmentList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="账号属性：" prop="role">
            <el-radio v-model="accountForm.role" :label="1">组员</el-radio>
            <el-radio v-model="accountForm.role" :label="2">管理员</el-radio>
            <el-radio v-model="accountForm.role" :label="3"
              >超级管理员</el-radio
            >
            <el-radio v-model="accountForm.role" :label="0">游客</el-radio>
          </el-form-item>
          <div class="sub">
            <el-button class="subBtn" @click="submitAffirm('accountForm')"
              >确定</el-button
            >
          </div>
        </el-form>
      </my-amend>
      <!-- 删除 -->
      <my-case
        v-show="isCase"
        @btnCancel="isCase = false"
        @btnConfirm="btnConfirm"
      ></my-case>
      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        prev-text="上一页"
        next-text="下一页"
        layout="total,prev,pager,next,jumper,slot"
        :total="total"
      >
      </el-pagination>
    </div>
    <div v-else>
      <limits tips="无权限"></limits>
    </div>
  </div>
</template>

<script>
import centTitle from "../../components/content/centTitle.vue";
import myAmend from "../../components/case/amend.vue";
import myCase from "../../components/case/case.vue";

import { request } from "../../API/request";
import { debounce } from "../../components/code/common";
import Limits from "../../components/common/limits.vue";

export default {
  components: {
    centTitle,
    myAmend,
    myCase,
    Limits,
  },
  data() {
    return {
      userName: "", // 姓名
      department: "", // 部门
      departmentList: ["技术部", "编辑部", "婚恋部", "其他"], // 部门数组
      accountList: [], // 账号数组
      query: 0, // 查询的数据类型(0 默认 1客户姓名 2客户编号 3会员等级)
      page: 1, // 当前页数
      pageSize: 10,
      total: 100, // 总条数
      // 提交的表单
      accountForm: {
        username: "",
        account: "",
        password: "",
        phone: "",
        department: "",
        role: 1,
      }, // 提交表单信息校检
      accountules: {
        username: [
          { required: true, message: "用户姓名不能为空", trigger: "blur" },
        ],
        account: [
          { required: true, message: "用户账号不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            min: 8,
            max: 18,
            pattern:
              /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,18}$/,
            message: "密码只能包含数字、英文、字符中的两种以上，长度6 - 18",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "手机号码不能为空", trigger: "blur" },
          {
            required: true,
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "请输入有效的手机号码",
            trigger: "blur",
          },
        ],
        department: [
          { required: true, message: "用户所属部门不能为空", trigger: "blur" },
        ],
      }, // 校检提交信息
      isAmend: false, // 修改弹框
      isCase: false, // 删除弹框
      delIndex: [], // 操作删除的索引
      opType: 0, // 操作类型修改（0）、新增（1）
      uid: JSON.parse(localStorage.getItem("userInfo")).uid,
      isLimints: false, // 是否有权限
    };
  },
  created() {
    this.getAccountList();
  },
  methods: {
    // 获取账号数据
    getAccountList() {
      let params = {
        page: this.page,
        size: this.pageSize,
      };
      console.log('query', this.query);
      if (this.query === 1) {
        params.username = this.userName;
      } else if (this.query === 2) {
        params.department = this.department;
      }
      console.log('params', params);
      request({
        url: "/user/list",
        method: "GET",
        params,
      }).then((res) => {
        if (res.code === 200) {
          this.page = res.data.current;
          this.total = res.data.total;
          this.accountList = res.data.records;
        } else if (res.code == 403) {
          this.$message.error("查询的对应数据不存在！");
        } else if (res.code === 502) {
          this.isLimints = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 查询姓名
    queryName: debounce(function () {
      if (this.userName == "") {
        this.$message({ message: "查询姓名不能为空！", type: "error" });
      } else {
        this.query = 1;
        this.getAccountList();
      }
    }, 300),
    // 查询部门
    querySection: debounce(function () {
      if (this.department == "") {
        this.$message({ message: "查询部门不能为空！", type: "error" });
      } else {
        this.query = 2;
        this.getAccountList();
      }
    }, 300),
    // 清空搜索栏中的值
    clearInfo: debounce(function () {
      this.page = 1;
      this.query = 0;
      this.getAccountList();
    }, 300),
    // 新增账号
    addList() {
      this.accountForm = {
        username: "",
        account: "",
        password: "",
        phone: "",
        department: "",
        role: 1,
      };
      this.opType = 1;
      this.isAmend = true;
    },
    // 改变页数对应序号
    indexMethod(index) {
      return (this.page - 1) * this.pageSize + index + 1;
    },
    // 用户勾选列表
    selectAll(e) {
      let list = [];
      for (let i = 0; i < e.length; i++) {
        if (this.uid != e[i].uid) {
          list.push(e[i].uid);
        } else {
          this.$message.error("不能勾选删除自己的账号！");
        }
      }
      this.delIndex = list;
    },
    // 修改按钮
    extensionEdit(row) {
      this.accountForm = JSON.parse(JSON.stringify(row));
      this.accountForm.uid = row.uid;
      this.opType = 0;
      this.isAmend = true;
    },
    // 点击确认按钮
    submitAffirm(accountForm) {
      this.$refs[accountForm].validate((valid) => {
        if (valid) {
          if (this.opType === 0) {
            request({
              url: "/user/edit",
              method: "POST",
              data: this.accountForm,
            }).then((res) => {
              if (res.code === 200) {
                this.getAccountList();
                this.$message.success("操作成功！");
              } else {
                this.$message.error(res.message);
              }
              this.isAmend = false;
            });
          } else if (this.opType === 1) {
            request({
              url: "/register",
              method: "POST",
              data: this.accountForm,
            }).then((res) => {
              if (res.code === 200) {
                this.getAccountList();
                this.$message.success("操作成功!");
              } else {
                this.$message.error(res.message);
              }
              this.isAmend = false;
            });
          }
        } else {
          this.$message.error("信息填写有误，请检查！");
          return false;
        }
      });
    },
    // 点击全选删除的按钮
    getAccountDel() {
      if (this.delIndex == "") {
        this.$message.error("请先勾选需要删除的数据！");
      } else {
        this.isCase = true;
      }
    },
    // 删除列表
    extensionDelete(row) {
      this.isCase = true;
      let del = [];
      del.push(row.uid);
      this.delIndex = del;
    },
    // 确认删除
    btnConfirm() {
      request({
        url: "/user/del",
        method: "POST",
        data: {
          uids: this.delIndex,
        },
      }).then((res) => {
        if (res.code === 200) {
          this.getAccountList();
          this.$message.success("删除成功！");
        } else {
          this.$message.error(res.message);
        }
        this.isCase = false;
      });
    },
    // 点击切换分页
    handleCurrentChange(val) {
      this.page = val;
      this.getAccountList();
    },
  },
};
</script>

<style lang="less" scoped>
#account {
  .user {
    margin: 16px 0 12px;
    padding: 10px 0 10px 24px;
    background: var(--gray);
    font-size: var(--subSize);
    font-weight: bold;
    display: flex;
    align-items: center;
    .el-input {
      width: 200px;
    }
    .el-button {
      background: var(--subPink);
      color: var(--white);
      font-size: var(--subSize);
      padding: 10px 24px;
      margin: 0 50px 0 12px;
    }
  }
  .add {
    padding: 10px 16px;
    font-size: 14px;
    color: var(--white);
    background: var(--blue);
  }
  .update {
    padding: 10px 16px;
    font-size: 14px;
    color: var(--white);
    background: var(--green);
  }
  .delete {
    padding: 10px 16px;
    font-size: 14px;
    color: var(--white);
    background: var(--info);
  }
  .el-table {
    margin: 16px 0 12px;
  }
  .accountForm {
    .isTitle {
      font-size: var(--mainSize);
      text-align: center;
      margin-bottom: 20px;
    }
    .sub {
      // width: calc(100% - 100px);
      display: flex;
      justify-content: center;
      .subBtn {
        background: var(--pink);
        color: var(--white);
      }
    }
  }
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
  }
}
</style>